const SCHEME = window.location.protocol.replace(/:$/, "")

const BASE_URL = new URL(window.location.origin)
BASE_URL.hostname = window.location.hostname.replace(/[^.]*\./, "")

const BASE_HOST = BASE_URL.host

export function getSubdomainUrl(subdomain: string, path: string = "") {
  if (path !== "" && !path.startsWith("/")) {
    throw new Error("Path must start with /")
  }
  return `${SCHEME}://${subdomain}.${BASE_HOST}${path}`
}

export function getAppSubdomainUrl(path: string = "") {
  return getSubdomainUrl("app", path)
}

export function getAdminSubdomainUrl(path: string = "") {
  return getSubdomainUrl("admin", path)
}

export function getEmbedSubdomainUrl(path: string = "") {
  return getSubdomainUrl("embed", path)
}

export function getEmbedSubdomainUrlIfNotDefault() {
  if (BASE_HOST === "oneschema.co") {
    return null
  }
  return getEmbedSubdomainUrl()
}
