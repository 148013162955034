import { createAction } from "@reduxjs/toolkit"

import { Environment } from "~/shared/api/environment"
import { List } from "~/shared/api/lists"
import { DenormalizedTargetAttribute, DenormalizedTemplate } from "~/shared/api/templates"
import { DenormalizedWorkspace } from "~/shared/api/workspaces"
import { ListAndWorkspaceId } from "~/shared/redux/lists"

// WORKSPACE ACTIONS
export const createWorkspace = createAction<DenormalizedWorkspace>("workspaces/create")
export const updateWorkspace = createAction<DenormalizedWorkspace>("workspaces/update")
export const deleteWorkspaceById = createAction<number>("workspaces/delete-by-id")

// LIST ACTIONS
export const createLists = createAction<List[]>("lists/create")
export const updateList = createAction<Partial<List>>("lists/update")
export const updateListWorkspaceId = createAction<{
  workspaceId: number
  listId: number
  newWorkspaceId: number
}>("lists/move-workspace")
export const deleteListById = createAction<ListAndWorkspaceId>("lists/delete")

// TEMPLATE ACTIONS
export const createTemplate = createAction<DenormalizedTemplate>("templates/create")
export const updateTemplate = createAction<DenormalizedTemplate>("templates/update")
export const deleteTemplateById = createAction<number>("templates/delete")

// TARGET ATTRIBUTE ACTIONS
export const createTargetAttribute = createAction<{
  templateId: number
  targetAttribute: DenormalizedTargetAttribute
}>("target-attributes/create")
export const updateTargetAttribute = createAction<DenormalizedTargetAttribute>(
  "target-attributes/update",
)
export const reorderTargetAttributes = createAction<{
  templateId: number
  oldIndex: number
  newIndex: number
}>("target-attributes/reorder")
export const deleteTargetAttributeById = createAction<{ templateId: number; id: number }>(
  "target-attributes/delete",
)

// ENVIRONMENT ACTIONS
export const createEnvironment = createAction<Environment>("environments/create")
export const updateEnvironment = createAction<Environment>("environments/update")
export const deleteEnvironmentById = createAction<number>("environments/delete")
