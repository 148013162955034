import { AG_GRID_ENTERPRISE_KEY } from "~/shared/util/constants"

export async function registerAgGrid() {
  const { ModuleRegistry } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-community/core"
  )
  const { LicenseManager } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/core"
  )
  const { ClientSideRowModelModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-community/client-side-row-model"
  )
  const { ClipboardModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/clipboard"
  )
  const { FiltersToolPanelModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/filter-tool-panel"
  )
  const { MenuModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/menu"
  )
  const { RangeSelectionModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/range-selection"
  )
  const { ServerSideRowModelModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/server-side-row-model"
  )
  const { SetFilterModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/set-filter"
  )
  const { RowGroupingModule } = await import(
    /* webpackChunkName: "ag-grid" */ "@ag-grid-enterprise/row-grouping"
  )

  LicenseManager.setLicenseKey(AG_GRID_ENTERPRISE_KEY)
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    MenuModule,
    FiltersToolPanelModule,
    SetFilterModule,
    RangeSelectionModule,
    ClipboardModule,
    RowGroupingModule,
  ])
}

export async function importAgGridReact<T = any>() {
  const lib = await import(/* webpackChunkName: "ag-grid" */ "@ag-grid-community/react")

  return {
    default: lib.AgGridReact as typeof lib.AgGridReact<T>,
  }
}
