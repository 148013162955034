import { Org } from "~/shared/api/orgs"
import { User } from "~/shared/api/users"

export function loadIntercom(user: User, org: Org) {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: "jwmgbua5",
      email: user.email,
      created_at: user.createdAt as unknown as number,
      name: user.name,
      user_id: String(user.id),
      company: {
        company_id: org.subdomain,
        name: org.name,
        is_self_serve: org.isSelfServe,
      },
    })
  }
}

export function showNewIntercomMessage(message?: string): boolean {
  if (window.Intercom) {
    window.Intercom("showNewMessage", message)
    return true
  } else {
    return false
  }
}
