import * as Sentry from "@sentry/react"

import { fastJsonStringify } from "~/shared/util/json"
import { JsonValue } from "~/shared/util/types"

/**
 * Log an event to various destinations, for debugging and monitoring.
 *
 * This logging is intended to be used for soft errors, which are errors that
 * have a fallback behavior, or there's a generic error handling in place which
 * is not giving us enough information for debugging or monitoring.
 *
 * == Destinations ==
 *
 * Sentry: for monitoring and debugging.
 *
 * Console: for FullStory of the embedder window, under customer's account.
 */
export function logEvent(
  level: "error" | "warn" | "info",
  message: string,
  options: {
    /** Passing an Error exception object will ensure stacktrace is logged. */
    exception?: Error
    /**
     * Whether the event should be printed out to the console.
     */
    shouldConsole?: "auto" | "yes" | "no"
    /**
     * Whether the event should be "captured" in Sentry.
     * In case of `auto`, it will be captured on error/warn, but not on info.
     */
    shouldCapture?: "auto" | "yes" | "no"
    /**
     * Any addition data desired to be logged.
     *
     * NOTE: Since we log everything to the console, which in turn shows up in
     * our and customer's FullStory, we should be mindful of not logging
     * sensitive data, such as PII fields.
     */
    extra?: Record<string, any>
  } = {},
) {
  const { exception, shouldCapture = "auto", shouldConsole = "auto" } = options

  // Create a copy of extra, and inject optional fields when present.
  const data = { ...options.extra }
  if (exception) {
    data["_.exception"] = exception
    data["_.exception.name"] = exception.name
    data["_.exception.message"] = exception.message
    data["_.exception.cause"] = exception.cause
  }

  switch (level) {
    case "error":
      if (shouldConsole !== "no") {
        // eslint-disable-next-line no-console
        console.error(`OSE: ${message}`, data)
      }
      if (shouldCapture !== "no") {
        // NOTE: Not using captureException since it excludes the message from
        // the title of the Sentry event, which also makes it unsearchable.
        // Sentry.captureException(exception, { extra: { message, ...data } })
        Sentry.captureMessage(message, { level: "error", extra: data })
      }
      break

    case "warn":
      if (shouldConsole !== "no") {
        // eslint-disable-next-line no-console
        console.warn(`OSE: ${message}`, data)
      }
      if (shouldCapture !== "no") {
        Sentry.captureMessage(message, { level: "warning", extra: data })
      }
      break

    case "info":
      if (shouldConsole !== "no") {
        // eslint-disable-next-line no-console
        console.info(`OSE: ${message}`, data)
      }
      if (shouldCapture === "yes") {
        Sentry.captureMessage(message, { level: "info", extra: data })
      }
      break

    default:
      // eslint-disable-next-line no-console
      console.error("OSE: Unknown OSE log level:", level)
  }
}

global.onunhandledrejection = (event: PromiseRejectionEvent) => {
  logEvent("info", `Unhandled promise rejection: ${event.reason}`, {
    shouldConsole: "no",
    extra: {
      "event.promise": String(event.promise),
      "event.reason": fastJsonStringify(event.reason as JsonValue),
    },
  })
}
