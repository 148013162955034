import classNames from "classnames"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { OverflowTooltip } from "~/shared/components/design-system/OverflowTooltip/OverflowTooltip"
import { renderMarkdownString } from "~/shared/util/markdown"

import "./Text.less"

export interface TextProps extends React.HTMLProps<HTMLSpanElement> {
  children?: React.ReactNode
  className?: string
  type?:
    | "primary"
    | "secondary"
    | "placeholder"
    | "white"
    | "error"
    | "gray50"
    | "link"
    | "bluePurple100"
    | "inherit"
  truncated?: boolean
  markdown?: boolean
  // TODO: make required and explicitly check 'false' below
  strKey?: false | string
  i18n?: {
    values?: any
    components?: any[]
  }
}

export default function Text(props: TextProps) {
  const { className, type, truncated, strKey, i18n, markdown, ...spanProps } = props
  // Trans component does not use suspense well for ns,
  // so using the hook as well
  const { t, i18n: i18nLib } = useTranslation()

  if (markdown && spanProps.children) {
    spanProps.dangerouslySetInnerHTML = {
      __html: renderMarkdownString(spanProps.children as string),
    }
    spanProps.children = undefined
  }

  if (strKey) {
    spanProps.children = <Trans t={t} i18nKey={strKey} {...i18n} />
  }

  const { children, ..._rest } = spanProps

  return truncated ? (
    <OverflowTooltip title={children}>
      <span
        className={classNames("Text", className, type || "primary", {
          markdown,
          truncated,
        })}
        {...spanProps}
        style={{ display: "inline" }}
        dir={i18nLib.dir()}
      />
    </OverflowTooltip>
  ) : (
    <span
      className={classNames("Text", className, type || "primary", {
        markdown,
      })}
      {...spanProps}
      dir={i18nLib.dir()}
    />
  )
}
