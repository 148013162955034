import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit"
import arrayMoveImmutable from "array-move"

import {
  DenormalizedTargetAttribute,
  DenormalizedTemplate,
  TargetAttribute,
  Template,
} from "~/shared/api/templates"
import {
  createTargetAttribute,
  createTemplate,
  deleteTargetAttributeById,
  deleteTemplateById,
  reorderTargetAttributes,
  updateTargetAttribute,
  updateTemplate,
} from "~/shared/redux/actions"
import {
  normalizeTargetAttribute,
  normalizeTemplate,
  normalizeTemplates,
} from "~/shared/redux/schema"

export const templatesAdapter = createEntityAdapter<Template>()
export const targetAttributesAdapter = createEntityAdapter<TargetAttribute>()

type TemplatesState = {
  templates: EntityState<Template>
  targetAttributes: EntityState<TargetAttribute>
}

/*******************************
 * TEMPLATE REDUCER OPERATIONS *
 *******************************/

const handleCreatedTemplate = (
  state: TemplatesState,
  action: { payload: DenormalizedTemplate },
) => {
  const { id } = action.payload
  const normalized = normalizeTemplate(action.payload)
  templatesAdapter.addOne(state.templates, normalized.entities.templates[id])
  targetAttributesAdapter.addMany(
    state.targetAttributes,
    normalized.entities.targetAttributes,
  )
}

const handleUpdatedTemplate = (
  state: TemplatesState,
  action: { payload: DenormalizedTemplate },
) => {
  const { id } = action.payload
  const normalized = normalizeTemplate(action.payload)
  templatesAdapter.updateOne(state.templates, {
    id,
    changes: normalized.entities.templates[id],
  })
  // Handle updated templates that were returned with new
  // TargetAttributes.
  targetAttributesAdapter.upsertMany(
    state.targetAttributes,
    normalized.entities.targetAttributes,
  )
}

const handleDeletedTemplateById = (
  state: TemplatesState,
  action: { payload: number },
) => {
  const { targetAttributeIds = [] } = state.templates.entities[action.payload] || {}
  templatesAdapter.removeOne(state.templates, action.payload)
  targetAttributesAdapter.removeMany(state.targetAttributes, targetAttributeIds)
}

/***************************************
 * TARGET ATTRIBUTE REDUCER OPERATIONS *
 ***************************************/

const handleCreatedTargetAttribute = (
  state: TemplatesState,
  action: {
    payload: { templateId: number; targetAttribute: DenormalizedTargetAttribute }
  },
) => {
  const template = state.templates.entities[action.payload.templateId]
  const { id } = action.payload.targetAttribute
  const normalized = normalizeTargetAttribute(action.payload.targetAttribute)
  if (template) {
    template.targetAttributeIds = [...template.targetAttributeIds, id]
    targetAttributesAdapter.addOne(
      state.targetAttributes,
      normalized.entities.targetAttributes[id],
    )
  }
}

const handleUpdatedTargetAttribute = (
  state: TemplatesState,
  action: { payload: DenormalizedTargetAttribute },
) => {
  const { id } = action.payload
  const normalized = normalizeTargetAttribute(action.payload)
  targetAttributesAdapter.updateOne(state.targetAttributes, {
    id,
    changes: normalized.entities.targetAttributes[id],
  })
}

const handleReorderedTargetAttributes = (
  state: TemplatesState,
  action: { payload: { templateId: number; oldIndex: number; newIndex: number } },
) => {
  const template = state.templates.entities[action.payload.templateId]
  if (template) {
    template.targetAttributeIds = arrayMoveImmutable(
      template.targetAttributeIds,
      action.payload.oldIndex,
      action.payload.newIndex,
    )
    template.targetAttributeIds.forEach((targetAttributeId, index) => {
      const stateTA = state.targetAttributes.entities[targetAttributeId]
      if (stateTA) {
        stateTA.index = index
      }
    })
  }
}

const handleDeletedTargetAttributeById = (
  state: TemplatesState,
  action: { payload: { templateId: number; id: number } },
) => {
  const template = state.templates.entities[action.payload.templateId]
  if (template) {
    template.targetAttributeIds = template.targetAttributeIds.filter(
      (taId) => taId !== action.payload.id,
    )
    targetAttributesAdapter.removeOne(state.targetAttributes, action.payload.id)
  }
}

/**************************
 * Reducer slice creation *
 **************************/

export const templatesSlice = createSlice({
  name: "templates",
  initialState: {
    templates: templatesAdapter.getInitialState(),
    targetAttributes: targetAttributesAdapter.getInitialState(),
  },
  reducers: {
    // Only used to initialize templates from viewData.
    initializeTemplatesFromViewData: (
      state: TemplatesState,
      action: PayloadAction<DenormalizedTemplate[]>,
    ) => {
      // Need to remove existing templates/target attributes so that old templates from previous embed sessions are cleared
      templatesAdapter.removeAll(state.templates)
      targetAttributesAdapter.removeAll(state.targetAttributes)
      const normalized = normalizeTemplates(action.payload)
      templatesAdapter.addMany(state.templates, normalized.entities.templates)
      targetAttributesAdapter.addMany(
        state.targetAttributes,
        normalized.entities.targetAttributes,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      // Template Actions
      .addCase(createTemplate, handleCreatedTemplate)
      .addCase(updateTemplate, handleUpdatedTemplate)
      .addCase(deleteTemplateById, handleDeletedTemplateById)
      // Target Attribute Actions
      .addCase(createTargetAttribute, handleCreatedTargetAttribute)
      .addCase(updateTargetAttribute, handleUpdatedTargetAttribute)
      .addCase(reorderTargetAttributes, handleReorderedTargetAttributes)
      .addCase(deleteTargetAttributeById, handleDeletedTargetAttributeById)
  },
})

export const { initializeTemplatesFromViewData } = templatesSlice.actions

export default templatesSlice.reducer
