import React from "react"
import ReactDOM from "react-dom"

export interface HeadProps {
  children: React.ReactNode
}

/**
 * Use ReactPortals to place object in the document head
 */
export default function Head(props: HeadProps) {
  return ReactDOM.createPortal(props.children, document.head)
}
