import createTheme from "@mui/material/styles/createTheme"
import MuiThemeProvider from "@mui/material/styles/ThemeProvider"
import AntdThemeProvider from "antd/es/config-provider"
import { getStyle } from "antd/es/config-provider/cssVariables"
import React, { ReactElement, useEffect, useState } from "react"

import { CustomizationOptions } from "~/shared/api/customization"
import { Hex, OneSchemaStyles, defaultStyles } from "~/shared/styles/defaultStyles"
import Head from "~/shared/util/Head"
import {
  COLOR_REGEX,
  addOpacity,
  convertOpacityToHexColor,
  darken,
} from "~/shared/util/styles"

interface ThemeContextState {
  styles: OneSchemaStyles
  applyStyleOverrides: (options: CustomizationOptions) => void
}

export interface ThemeProviderProps {
  children: React.ReactNode
  parent?: HTMLElement | null
}

function applyAntdTheme(styles: OneSchemaStyles, parent: HTMLElement) {
  // antd's support for themes is experimental
  // we need more flexibility to be able to apply them
  // as a style field on an HTMLElement
  // so we have to use the function antd uses
  // to make its themes and 'parse' it
  // this function is not publicly exposed, had to
  // go through their source to find it.
  const antStyles = getStyle("ant", {
    primaryColor: styles.ColorPrimary100,
    successColor: styles.ColorSuccessGreen100,
    errorColor: styles.ColorErrorRed100,
    warningColor: styles.ColorWarningYellow100,
  })
  // the return wraps the keys in 'root: { }` for putting in <style>
  // but we want to put on a div, so we parse:
  const start = antStyles.indexOf("{") + 1
  const end = antStyles.indexOf("}")
  const antProps = antStyles.substring(start, end).trim().split(";")
  antProps.forEach((property) => {
    const [key, value] = property.split(":")
    if (key && value) {
      parent.style.setProperty(key.trim(), value.trim())
    }
  })
}

// Util function for parsing the styles from a customization object into the proper hash
// of overrides for defaultStyles.
function processCustomizationStyles(
  custStyles: CustomizationOptions,
): Partial<OneSchemaStyles> {
  const overrides: Partial<OneSchemaStyles> = {}
  const backgroundColor =
    custStyles.backgroundPrimaryColor &&
    COLOR_REGEX.test(custStyles.backgroundPrimaryColor)
      ? custStyles.backgroundPrimaryColor
      : defaultStyles.ColorBackgroundPrimary

  /******************
   * GENERAL COLORS *
   ******************/

  // function to apply override to all relevant colors
  // based on given key and values
  const overrideColor = (
    override: Hex,
    baseName: string,
    numbers: number[],
    modifier?: string,
  ) => {
    let transform = convertOpacityToHexColor
    if (modifier === "Transparent") {
      transform = addOpacity
    }

    numbers.forEach((number) => {
      // access color names based on string key
      // using naming conventions ie. 'ColorPrimaryTransparent10'
      overrides[`${baseName}${modifier || ""}${number}` as keyof OneSchemaStyles] =
        number > 100
          ? darken(override, (number - 100) / 100)
          : transform(override, number / 100, backgroundColor)
    })
  }

  if (custStyles.primaryColor && COLOR_REGEX.test(custStyles.primaryColor)) {
    overrideColor(custStyles.primaryColor, "ColorPrimary", [100, 80, 20, 15, 10])
    overrideColor(custStyles.primaryColor, "ColorPrimary", [15, 10, 8, 5], "Transparent")
  }

  if (custStyles.successColor && COLOR_REGEX.test(custStyles.successColor)) {
    overrideColor(custStyles.successColor, "ColorSuccessGreen", [120, 100, 70, 40, 20])
  }

  if (custStyles.warningColor && COLOR_REGEX.test(custStyles.warningColor)) {
    overrideColor(custStyles.warningColor, "ColorWarningYellow", [120, 100, 40, 20])
    overrideColor(
      custStyles.warningColor,
      "ColorWarningYellow",
      [30, 25, 20],
      "Transparent",
    )
  }

  if (custStyles.errorColor && COLOR_REGEX.test(custStyles.errorColor)) {
    overrideColor(custStyles.errorColor, "ColorErrorRed", [120, 100, 70, 15, 10])
    overrideColor(custStyles.errorColor, "ColorErrorRed", [30, 25, 20], "Transparent")
  }

  /********************************
   * MODAL *
   ********************************/

  if (custStyles.modalMaskColor) {
    overrides.ColorModalMask = custStyles.modalMaskColor
  }

  if (custStyles.modalBorderRadius) {
    overrides.ModalBorderRadius = custStyles.modalBorderRadius
  }

  /********************************
   * HEADER / FOOTER / BACKGROUND *
   ********************************/
  if (custStyles.headerColor && COLOR_REGEX.test(custStyles.headerColor)) {
    overrides.ColorHeader = custStyles.headerColor
  }
  if (custStyles.footerColor && COLOR_REGEX.test(custStyles.footerColor)) {
    overrides.ColorFooter = custStyles.footerColor
  }
  if (custStyles.borderColor && COLOR_REGEX.test(custStyles.borderColor)) {
    overrides.ColorBorder = custStyles.borderColor
  }
  if (
    custStyles.backgroundPrimaryColor &&
    COLOR_REGEX.test(custStyles.backgroundPrimaryColor)
  ) {
    const primaryBackgroundColor = custStyles.backgroundPrimaryColor
    overrides.ColorBackgroundPrimary = primaryBackgroundColor
    overrides.ColorBackgroundPrimaryDark02 = darken(primaryBackgroundColor, 0.02)
  }
  if (
    custStyles.backgroundSecondaryColor &&
    COLOR_REGEX.test(custStyles.backgroundSecondaryColor)
  ) {
    const secondaryBackgroundColor = custStyles.backgroundSecondaryColor
    overrides.ColorBackgroundSecondary = secondaryBackgroundColor
    overrides.ColorBackgroundSecondaryDark02 = darken(secondaryBackgroundColor, 0.02)
    overrideColor(
      custStyles.backgroundSecondaryColor,
      "ColorBackgroundSecondary",
      [0],
      "Transparent",
    )
  }

  /*********
   * FONTS *
   *********/

  if (custStyles.fontFamily && custStyles.fontUrl) {
    overrides.FontFamily = custStyles.fontFamily
    overrides.FontUrl = custStyles.fontUrl
  }

  if (custStyles.fontColorPrimary && COLOR_REGEX.test(custStyles.fontColorPrimary)) {
    overrides.FontColorPrimary = custStyles.fontColorPrimary
  }
  if (custStyles.fontColorSecondary && COLOR_REGEX.test(custStyles.fontColorSecondary)) {
    overrides.FontColorSecondary = custStyles.fontColorSecondary
  }
  if (
    custStyles.fontColorPlaceholder &&
    COLOR_REGEX.test(custStyles.fontColorPlaceholder)
  ) {
    overrides.FontColorPlaceholder = custStyles.fontColorPlaceholder
  }

  /***********
   * BUTTONS *
   ***********/
  if (custStyles.buttonBorderRadius) {
    overrides.ButtonBorderRadius = custStyles.buttonBorderRadius
  }
  // Primary Button
  if (
    custStyles.buttonPrimaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryFillColor)
  ) {
    const primaryFillColor = custStyles.buttonPrimaryFillColor
    overrides.ButtonPrimaryFillColor = primaryFillColor
    overrides.ButtonPrimaryFillColorHover = darken(primaryFillColor, 0.2)
  }
  if (
    custStyles.buttonPrimaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryStrokeColor)
  ) {
    overrides.ButtonPrimaryStrokeColor = custStyles.buttonPrimaryStrokeColor
  }
  if (
    custStyles.buttonPrimaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonPrimaryTextColor)
  ) {
    overrides.ButtonPrimaryTextColor = custStyles.buttonPrimaryTextColor
  }
  // Secondary Button
  if (
    custStyles.buttonSecondaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryFillColor)
  ) {
    const secondaryFillColor = custStyles.buttonSecondaryFillColor
    overrides.ButtonSecondaryFillColor = secondaryFillColor
    overrides.ButtonSecondaryFillColorHover = darken(secondaryFillColor, 0.2)
  }
  if (
    custStyles.buttonSecondaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryStrokeColor)
  ) {
    overrides.ButtonSecondaryStrokeColor = custStyles.buttonSecondaryStrokeColor
  }
  if (
    custStyles.buttonSecondaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonSecondaryTextColor)
  ) {
    overrides.ButtonSecondaryTextColor = custStyles.buttonSecondaryTextColor
  }
  // Tertiary Button
  if (
    custStyles.buttonTertiaryFillColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryFillColor)
  ) {
    const tertiaryFillColor = custStyles.buttonTertiaryFillColor
    overrides.ButtonTertiaryFillColor = tertiaryFillColor
    overrides.ButtonTertiaryFillColorHover = darken(tertiaryFillColor, 0.02)
  }
  if (
    custStyles.buttonTertiaryStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryStrokeColor)
  ) {
    overrides.ButtonTertiaryStrokeColor = custStyles.buttonTertiaryStrokeColor
  }
  if (
    custStyles.buttonTertiaryTextColor &&
    COLOR_REGEX.test(custStyles.buttonTertiaryTextColor)
  ) {
    overrides.ButtonTertiaryTextColor = custStyles.buttonTertiaryTextColor
  }
  // Alert Button
  if (
    custStyles.buttonAlertFillColor &&
    COLOR_REGEX.test(custStyles.buttonAlertFillColor)
  ) {
    const alertFillColor = custStyles.buttonAlertFillColor
    overrides.ButtonAlertFillColor = alertFillColor
    overrides.ButtonAlertFillColorHover = darken(alertFillColor, 0.05)
  }
  if (
    custStyles.buttonAlertStrokeColor &&
    COLOR_REGEX.test(custStyles.buttonAlertStrokeColor)
  ) {
    overrides.ButtonAlertStrokeColor = custStyles.buttonAlertStrokeColor
  }
  if (
    custStyles.buttonAlertTextColor &&
    COLOR_REGEX.test(custStyles.buttonAlertTextColor)
  ) {
    overrides.ButtonAlertTextColor = custStyles.buttonAlertTextColor
  }

  return overrides
}

export const ThemeContext = React.createContext({} as ThemeContextState)

// Provider that manages the Styles object. If we are in an embed org, use a mix of the
// defaultStyles overriden by the styles object from customization. Otherwise (eg. in
// workspaces), just use the defaultStyles.
export default function ThemeProvider(props: ThemeProviderProps): ReactElement | null {
  const [styles, setStyles] = useState<OneSchemaStyles>(defaultStyles)
  const setCustomization = (options: CustomizationOptions) => {
    const overrides = processCustomizationStyles(options)
    setStyles({ ...defaultStyles, ...overrides })
  }

  useEffect(() => {
    if (props.parent) {
      Object.keys(styles).forEach((key: string) => {
        props.parent!.style.setProperty(`--${key}`, styles[key as keyof OneSchemaStyles])
      })

      applyAntdTheme(styles, props.parent)

      // Overrides AntD Styles and anything not using new Typography changes to follow font
      // family from styles.
      props.parent.style.fontFamily = styles["FontFamily"]
      props.parent.style.color = styles["FontColorPrimary"]
    }
  }, [styles, props.parent])

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: styles.ColorPrimary100,
      },
      secondary: {
        main: styles.ColorSecondary100,
      },
      error: {
        main: styles.ColorErrorRed100,
      },
      success: {
        main: styles.ColorSuccessGreen100,
      },
    },
  })

  return (
    <ThemeContext.Provider value={{ styles, applyStyleOverrides: setCustomization }}>
      <AntdThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <Head>
            <style>
              @import url({styles.FontUrl}); @import url({styles.FontMonoUrl});
            </style>
          </Head>
          {props.children}
        </MuiThemeProvider>
      </AntdThemeProvider>
    </ThemeContext.Provider>
  )
}
