/**
 * Network monitoring utilities
 */

import axios from "axios"

import { Org } from "~/shared/api/orgs"
import { MINUTE, SECOND } from "~/shared/constants"
import { sleep } from "~/shared/util/async"
import { Feature, isFeatureEnabled } from "~/shared/util/gating"
import { logEvent } from "~/shared/util/logging"
import viewManager from "~/shared/util/viewManager"

interface NetworkStatus extends Record<string, any> {
  requestTime: number
  requestTimeStr: string
  serverDelay: number | null
  roundTripTime: number | null
  navigatorOnline: boolean
}

let latestNetworkStatus: NetworkStatus | null = null

// == Generic internet connection ==

function offlineListener() {
  logEvent("info", "NetworkMonitor: Browser is offline", {
    extra: {
      event_id: "browser-offline",
      latestNetworkStatus,
    },
  })
}
window.addEventListener("offline", offlineListener)

function onlineListener() {
  logEvent("info", "NetworkMonitor: Browser is online", {
    extra: {
      event_id: "browser-online",
      latestNetworkStatus,
    },
  })
}
window.addEventListener("online", onlineListener)

// == OS server reachability ==

const MONITOR_INTERVAL = 30 * SECOND
const MONITOR_REQUEST_TIMEOUT = 20 * SECOND // Better be less than MONITOR_INTERVAL
const MONITOR_DURATION = 10 * MINUTE

function onServerReachable(roundTripTime: number) {
  logEvent(
    "info",
    `NetworkMonitor: OS server reachable. round-trip: ${roundTripTime / SECOND} sec`,
    {
      extra: {
        event_id: "server-reachable",
        latestNetworkStatus,
      },
    },
  )
}

function onServerUnreachable() {
  logEvent("info", "NetworkMonitor: OS server unreachable", {
    extra: {
      event_id: "server-unreachable",
      latestNetworkStatus,
    },
  })
}

const TOP_URL = new URL(
  (window.location !== window.parent.location ? document.referrer : "") ||
    document.location.href,
)

const serverMonitorReasons: Map<string, number> = new Map()
let serverMonitorStartTime: number | null = null
let baseOrg: Org | undefined

export async function monitorServerReachability(reason: string): Promise<void> {
  serverMonitorReasons.set(reason, (serverMonitorReasons.get(reason) ?? 0) + 1)

  if (baseOrg == null) {
    const org = viewManager.get("org")
    baseOrg = org?.parentOrg ?? org
    if (baseOrg == null) {
      // Embedder is not initialized yet, no need to monitor yet.
      return
    }
  }

  if (!isFeatureEnabled(Feature.ServerReachabilityPing)) {
    return
  }

  // Only allow one monitor to run at a time. On every call, we reset the
  // duration timer, though.
  const isPinging = serverMonitorStartTime != null
  serverMonitorStartTime = Date.now()
  if (isPinging) {
    return
  }

  while (Date.now() < serverMonitorStartTime + MONITOR_DURATION) {
    const nowReachable = await pingServer({
      reasons: serverMonitorReasons,
      baseOrg,
      onServerReachable,
      onServerUnreachable,
    })
    if (nowReachable) {
      logEvent("info", "NetworkMonitor: Stop monitoring", {
        extra: {
          latestNetworkStatus,
        },
      })
      // Stop pinging when the server is reachable.
      break
    }
    await sleep(MONITOR_INTERVAL)
  }

  serverMonitorStartTime = null
}

// NOTE: We're not using the common client here because we don't want the error
// logging or retry behaviors, and a shorter timeout.
const pingAxios = axios.create({
  headers: { "Content-Type": "application/json" },
  timeout: MONITOR_REQUEST_TIMEOUT,
})

interface PingResponse {
  time: string
}

async function pingServer({
  reasons,
  baseOrg,
  onServerReachable,
  onServerUnreachable,
}: {
  reasons: Map<string, number>
  baseOrg: Org
  onServerReachable: (roundTripTime: number) => void
  onServerUnreachable: () => void
}): Promise<boolean | null> {
  const requestTime: number = Date.now()

  let serverDelay: number | null = null
  try {
    const {
      status,
      data: { time },
    } = await pingAxios.post<PingResponse>("/api/ping", null, {
      params: {
        base_org_id: baseOrg.id ?? "",
        base_org_subdomain: baseOrg.subdomain ?? "",
        top_url_host: TOP_URL.host,
        reasons: [...reasons].map(([k, v]) => `${k}:${v}`).join(","),
      },
    })
    if (status !== 200) {
      // Non-OK responses are considered unreachable.
    }
    const serverTime: number = new Date(time).valueOf()
    serverDelay = serverTime - requestTime
  } catch {
    // Network errors are considered unreachable.
  }

  const resolvedTime = Date.now()
  const roundTripTime = resolvedTime - requestTime

  if (latestNetworkStatus && latestNetworkStatus.requestTime > requestTime) {
    // We have result of a newer ping request, skipping this one.
    return null
  }

  const previouslyReachable =
    latestNetworkStatus == null || latestNetworkStatus.serverDelay != null
  const previouslyUnreachable =
    latestNetworkStatus && latestNetworkStatus.serverDelay == null

  const nowReachable = serverDelay != null
  const switchedToReachable = previouslyUnreachable && nowReachable
  const switchedToUnreachable = previouslyReachable && !nowReachable

  latestNetworkStatus = {
    requestTime,
    requestTimeStr: new Date(requestTime).toISOString(),
    serverDelay,
    roundTripTime,
    navigatorOnline: navigator.onLine,
  }

  if (switchedToReachable) {
    onServerReachable(roundTripTime)
  } else if (switchedToUnreachable) {
    onServerUnreachable()
  }

  return nowReachable
}
