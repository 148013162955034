/**
 * Asynchronous utilities.
 */

/**
 * Awaitable delayed success.
 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/**
 * Awaitable delayed failure.
 */
export function detonator(ms: number) {
  return new Promise((_, reject) => setTimeout(reject, ms))
}

/**
 * Retry a function every `interval` ms, up to `timeout` ms.
 */
export async function retryWithTimeout<T>(
  interval: number,
  timeout: number,
  resolver: (() => T) | (() => Promise<T>),
) {
  for (let retryCount = 0; retryCount < timeout / interval; retryCount++) {
    try {
      const result = await resolver()
      return result
    } catch {
      await sleep(interval)
    }
  }
  throw new Error(`Timeout after ${timeout} ms`)
}
