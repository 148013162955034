import { OneSchemaErrorSeverity } from "@oneschema/importer"

import { logEvent } from "~/shared/util/logging"
import { postMessageToParentWindow } from "~/shared/util/window"

// TODO: This is temporary and will be updated with the SDK error revamp.
export function postErrorToParentWindow(message: string) {
  postMessageToParentWindow({
    messageType: "nonclosing-error",
    message,
  })
}

export function postErrorV2ToParentWindow(
  message: string,
  severity?: OneSchemaErrorSeverity,
) {
  postMessageToParentWindow({
    messageType: "error-v2",
    message,
    severity: severity || OneSchemaErrorSeverity.Error,
  })

  // NOTE: This may produce some duplicate logging events but will be
  // reassessed shortly.
  logEvent("info", `TPW: ${message}`, { shouldConsole: "no" })
}
