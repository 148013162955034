import { configureStore, createSelector } from "@reduxjs/toolkit"
import { useMemo } from "react"
import { TypedUseSelectorHook, shallowEqual, useDispatch, useSelector } from "react-redux"

import {
  ENVIRONMENT_NAME_DEVELOPMENT,
  ENVIRONMENT_NAME_PRODUCTION,
  ENVIRONMENT_NAME_SANDBOX,
  Environment,
} from "~/shared/api/environment"
import { List } from "~/shared/api/lists"
import { TargetAttribute, Template } from "~/shared/api/templates"
import environmentReducer, { environmentsAdapter } from "~/shared/redux/environments"
import listsReducer, { listsAdapter } from "~/shared/redux/lists"
import {
  normalizeEnvironments,
  normalizeWorkspaces,
  partialInitSchema,
} from "~/shared/redux/schema"
import templatesDataReducer, {
  initializeTemplatesFromViewData,
  targetAttributesAdapter,
  templatesAdapter,
} from "~/shared/redux/templates-data"
import workspacesReducer, { workspacesAdapter } from "~/shared/redux/workspaces"
import viewManager from "~/shared/util/viewManager"

const store = configureStore({
  reducer: {
    templatesData: templatesDataReducer,
    workspaces: workspacesReducer,
    lists: listsReducer,
    environments: environmentReducer,
  },
})

// Put this in a function so that we can also use it when
// re-initializing data when embedding OneSchema.
export function initializeStoreFromViewData() {
  // Initialize Templates and TargetAttributes in the store.
  const templates = viewManager.getOnceThenReadFromStore("templates")
  store.dispatch(initializeTemplatesFromViewData(templates || []))

  // Initialize Workspaces and Lists in the store.
  const normalizedWorkspaces = normalizeWorkspaces(
    viewManager.getOnceThenReadFromStore("workspaces") || [],
  )
  const normalizedEnvironments = normalizeEnvironments(
    viewManager.getOnceThenReadFromStore("environments") || [],
  )
  store.dispatch(
    partialInitSchema({
      workspaces: normalizedWorkspaces.entities.workspaces,
      lists: normalizedWorkspaces.entities.lists,
      environments: normalizedEnvironments.entities.environments,
    }),
  )
}

initializeStoreFromViewData()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

/*************
 * SELECTORS *
 *************/

// Redux Toolkit's EntityAdapter unfortunately doesn't come with a
// selector to convert an array of ids into an array of objects.
// This type of selector is more complicated because it needs to use
// caching/memoization in order to avoid re-renders, so we define
// this function to help create that type of selector given a selector
// that returns the .entities field of the EntityAdapter.
function createUseByIdsSelector<T>(
  entitiesSelector: (state: RootState) => { [id: number]: T },
): (ids: number[]) => T[] {
  // When selecting Entities by ids, we need to create a new selector
  // for each component to handle basic caching per-component.
  //
  // https://react-redux.js.org/api/hooks#using-memoizing-selectors
  const createByIdsSelector = (selectorIds: number[]) =>
    createSelector([entitiesSelector], (entities: { [id: number]: T }) =>
      selectorIds.map((id) => entities[id]),
    )

  return (ids: number[]): T[] => {
    // We'll use useMemo so we get the same selector function back while
    // the ids array is the same.
    const selector = useMemo(() => createByIdsSelector(ids), [ids])

    // Then use shallowEqual here to prevent excessive re-renders.
    return useSelector(selector, shallowEqual)
  }
}

/**************
 * WORKSPACES *
 **************/

const selectWorkspaces = (state: RootState) => state.workspaces
const workspaceSelectors = workspacesAdapter.getSelectors(selectWorkspaces)

export const useAllWorkspaces = () => useAppSelector(workspaceSelectors.selectAll)
export const useWorkspaceById = (id: number) =>
  useAppSelector((state: RootState) => workspaceSelectors.selectById(state, id))

export const useOrgDefaultWorkspace = () => {
  const allWorkspaces = useAllWorkspaces()
  return allWorkspaces.find((ws) => ws.isPublic && ws.isDefault && !ws.creator)
}

export const useUserDefaultWorkspace = (userId: number) => {
  const allWorkspaces = useAllWorkspaces()
  return allWorkspaces.find(
    (ws) => ws.isDefault && ws.creator && ws.creator.id === userId,
  )
}

/*********
 * LISTS *
 *********/

export const useListsByIds = createUseByIdsSelector(
  (state: RootState) => state.lists.entities,
)

const selectLists = (state: RootState) => state.lists
const listSelectors = listsAdapter.getSelectors(selectLists)

export const useAllLists = () => useAppSelector(listSelectors.selectAll)
export const useLists = () => useAppSelector(listSelectors.selectEntities)
export const useListById = (id?: number) =>
  useAppSelector((state: RootState) =>
    id !== undefined ? listSelectors.selectById(state, id) : undefined,
  )

export const useWorkspaceLists = (workspaceId: number): List[] | undefined => {
  const allLists = useAllLists()
  return useMemo(
    () => allLists.filter((list) => list && list.workspaceId === workspaceId),
    [allLists, workspaceId],
  )
}

/*********************************
 * TEMPLATES & TARGET ATTRIBUTES *
 *********************************/

const selectTemplates = (state: RootState) => state.templatesData.templates
const selectTargetAttributes = (state: RootState) => state.templatesData.targetAttributes

const templateSelectors = templatesAdapter.getSelectors(selectTemplates)
const targetAttributeSelectors =
  targetAttributesAdapter.getSelectors(selectTargetAttributes)

export const useAllTemplates = () => useAppSelector(templateSelectors.selectAll)
export const useTemplates = () => useAppSelector(templateSelectors.selectEntities)
export const useTemplateById = (id?: number) =>
  useAppSelector((state: RootState) =>
    id !== undefined ? templateSelectors.selectById(state, id) : undefined,
  )
export const useTargetAttributes = () =>
  useAppSelector(targetAttributeSelectors.selectEntities)
export const useTargetAttributesByIds = createUseByIdsSelector<
  TargetAttribute | undefined
>((state: RootState) => state.templatesData.targetAttributes.entities)

// A helper type and selector for getting both a Template and its
// TargetAttributes from the id of a template.
interface TemplateAndTargetAttributes {
  template: Template
  targetAttributes: (TargetAttribute | undefined)[]
}

export function useTemplateAndTargetAttributesById(
  templateId?: number,
): Partial<TemplateAndTargetAttributes>
export function useTemplateAndTargetAttributesById(
  templateId: number,
): TemplateAndTargetAttributes
export function useTemplateAndTargetAttributesById(
  templateId?: number,
): Partial<TemplateAndTargetAttributes> {
  const template = useTemplateById(templateId)
  const targetAttributes = useTargetAttributesByIds(
    template ? template.targetAttributeIds : [],
  )
  return { template, targetAttributes }
}

/****************
 * ENVIRONMENTS *
 ****************/

const selectEnvironments = (state: RootState) => state.environments
const environmentSelectors = environmentsAdapter.getSelectors(selectEnvironments)

export function useAllEnvironments(options?: {
  includeSandboxEnvironment?: boolean
}): Environment[] {
  const environments = useAppSelector(environmentSelectors.selectAll)
  // We use memoization here because the result of this function may be used in useEffects;
  // Filter always creates a new array, so we need to memoize it to avoid infinite re-render.
  const filteredAndSortedEnvs = useMemo(() => {
    let envs = [...environments]
    if (!options?.includeSandboxEnvironment) {
      envs = environments.filter((e) => e.name !== ENVIRONMENT_NAME_SANDBOX)
    }
    // Sort environments by putting production environment first
    envs.sort((a, b) => {
      if (a.name === ENVIRONMENT_NAME_PRODUCTION) {
        return -1
      }
      if (b.name === ENVIRONMENT_NAME_PRODUCTION) {
        return 1
      }
      return 0
    })

    return envs
  }, [environments, options?.includeSandboxEnvironment])
  return filteredAndSortedEnvs
}
export const useEnvironmentById = (id?: number) =>
  useAppSelector((state: RootState) =>
    id !== undefined ? environmentSelectors.selectById(state, id) : undefined,
  )
export const useProdEnvironment = (): Environment | undefined => {
  const allEnvironments = useAllEnvironments()
  return allEnvironments.find((e) => e.name === ENVIRONMENT_NAME_PRODUCTION)
}

export const useSandboxEnvironment = (): Environment | undefined => {
  const environments = useAppSelector(environmentSelectors.selectAll)
  return environments.find((e) => e.name === ENVIRONMENT_NAME_SANDBOX)
}

export const useDevelopmentEnvironment = (): Environment | undefined => {
  const environments = useAppSelector(environmentSelectors.selectAll)
  return environments.find((e) => e.name === ENVIRONMENT_NAME_DEVELOPMENT)
}

export const useNonProdEnvironments = (options?: {
  includeSandboxEnvironment?: boolean
}) => {
  // We use memoization here because the result of this function may be used in useEffects;
  // Filter always creates a new array, so we need to memoize it to avoid infinite re-render.
  const allEnvironments = useAllEnvironments(options)
  const filteredEnvironments = useMemo(() => {
    return allEnvironments.filter((e) => e.name !== ENVIRONMENT_NAME_PRODUCTION)
  }, [allEnvironments])
  return filteredEnvironments
}

export default store
