// These are all the default styles / colors that will be read (and overwritten) by the
// ThemeProvider.
export const defaultStyles: OneSchemaStyles = {
  OneSchemaBlue: "#002BF3",
  // PRIMARY COLORS
  ColorPrimary100: "#002BF3",
  ColorPrimary80: "#3355F5",
  ColorPrimary20: "#CCD5FD",
  ColorPrimary15: "#D9DFFD",
  ColorPrimary10: "#E6EAFE",
  ColorPrimaryTransparent15: "#002BF326",
  ColorPrimaryTransparent10: "#002BF31A",
  ColorPrimaryTransparent8: "#002BF314",
  ColorPrimaryTransparent5: "#002BF30D",
  // SECONDARY COLORS
  ColorSecondary100: "#686C80",
  ColorSecondary20: "#DADAE3",
  // ERROR COLORS
  ColorErrorRed120: "#C01517",
  ColorErrorRed100: "#E72426",
  ColorErrorRed70: "#EE6667",
  ColorErrorRed15: "#FBDEDE",
  ColorErrorRed10: "#FDE9E9",
  ColorErrorRedTransparent30: "#E724264d",
  ColorErrorRedTransparent25: "#E7242640",
  ColorErrorRedTransparent20: "#E7242633",
  // SUCCESS COLORS
  ColorSuccessGreen120: "#2D7C2B",
  ColorSuccessGreen100: "#389B36",
  ColorSuccessGreen70: "#74C172",
  ColorSuccessGreen40: "#AFD7AF",
  ColorSuccessGreen20: "#D7EDD7",
  // WARNING COLORS
  ColorWarningYellow120: "#C08513",
  ColorWarningYellow100: "#E9A41E",
  ColorWarningYellow40: "#F6DBA5",
  ColorWarningYellow20: "#FBEDD2",
  ColorWarningYellowTransparent30: "#E9A41E4d",
  ColorWarningYellowTransparent25: "#E9A41E40",
  ColorWarningYellowTransparent20: "#E9A41E33",
  // GRAY SCALE
  ColorBlack: "#333333",
  ColorGray80: "#595959",
  ColorGray50: "#989898",
  ColorGray20: "#D6D6D6",
  ColorGray10: "#EBEBEB",
  ColorGray5: "#F5F5F5",
  ColorGray2: "#FAFAFA",
  ColorWhite: "#FFFFFF",
  // LIGHTEN / DARKEN
  ColorBlackTransparent10: "#33333319",
  ColorBlackTransparent20: "#33333333",
  ColorBlackTransparent50: "#33333380",
  ColorWhiteTransparent30: "#FFFFFF4d",
  // SELECTOR COLORS (eg. WorkspaceColorSelect)
  ColorSelectorRed100: "#F5222D",
  ColorSelectorRedTransparent10: "#F5222D1A",
  ColorSelectorRed10: "#FEE9EB",
  ColorSelectorYellow100: "#EFC600",
  ColorSelectorYellow10: "#FEFAE6",
  ColorSelectorYellowTransparent10: "#EFC6001A",
  ColorSelectorBlue100: "#40A9FF",
  ColorSelectorBlue10: "#ECF7FF",
  ColorSelectorBlueTransparent10: "#40A9FF1A",
  ColorSelectorGreen100: "#52C41A",
  ColorSelectorGreen10: "#EEFAE9",
  ColorSelectorBluePurple100: "#2F7AEB",
  ColorSelectorBluePurple10: "#EBF2FE",
  ColorSelectorPurple100: "#722ED1",
  ColorSelectorPurple10: "#F1EBFB",
  ColorSelectorPink100: "#EB2F96",
  ColorSelectorPink10: "#FEEBF5",
  ColorSelectorPurplePink100: "#BA2FEB",
  ColorSelectorPurplePink10: "#F9EBFE",
  ColorSelectorGreenBlue100: "#33C18E",
  ColorSelectorGreenBlue10: "#EBF9F4",
  ColorSelectorOrange100: "#EF8F00",
  ColorSelectorOrange120: "#C97D0A",
  ColorSelectorOrange10: "#FEF4E6",
  // TODO: (michael) please test these colors later ... we might want to change
  ColorSelectorGray100: "#595959",
  ColorSelectorGray10: "#EEEEEE",
  // Excel green used to color excel icon (exports)
  ColorExcelGreen: "#1D6F42",
  // HEADER, FOOTER, BACKGROUND
  ColorHeader: "#FFFFFF",
  ColorFooter: "#FFFFFF",
  ColorBorder: "#EBEBEB",
  ColorBackgroundPrimary: "#FFFFFF",
  ColorBackgroundPrimaryDark02: "#F9F9F9",
  ColorBackgroundSecondary: "#F6F6FC",
  ColorBackgroundSecondaryDark02: "#EEEEF9",
  ColorBackgroundSecondaryTransparent0: "#F6F6FC00",

  // MODAL
  ColorModalMask: "#00000073",
  ModalBorderRadius: "8px",

  // BUTTONS
  ButtonBorderRadius: "4px",
  ButtonBorderColor: "#D9D9D9",

  ButtonPrimaryFillColor: "#002BF3",
  ButtonPrimaryFillColorHover: "#0022C2",
  ButtonPrimaryStrokeColor: "#002BF3",
  ButtonPrimaryTextColor: "#FFFFFF",

  ButtonSecondaryFillColor: "#FFFFFF",
  ButtonSecondaryFillColorHover: "#CCCCCC",
  ButtonSecondaryStrokeColor: "#CCCCCC",
  ButtonSecondaryTextColor: "#333333",

  ButtonTertiaryFillColor: "#F6F6FC",
  ButtonTertiaryFillColorHover: "#EEEEF9",
  ButtonTertiaryStrokeColor: "#EEEEF9",
  ButtonTertiaryTextColor: "#333333",

  ButtonAlertFillColor: "#FDE9E9",
  ButtonAlertFillColorHover: "#FBDEDE",
  ButtonAlertStrokeColor: "#FBDEDE",
  ButtonAlertTextColor: "#F5222D",

  // FONTS
  FontUrl:
    "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap",
  FontFamily: "'Inter', sans-serif",
  FontMonoUrl:
    "https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600;700&display=swap",
  FontFamilyMono: "'Source Code Pro', monospace",
  FontColorPrimary: "#333333",
  FontColorSecondary: "#595959",
  FontColorPlaceholder: "#BFBFBF",
  FontColorLink: "#002BF3",
}

// https://stackoverflow.com/questions/42584228/how-can-i-define-a-type-for-a-css-color-in-typescript
export type Hex = `#${string}`
export type RGB = {
  r: number
  g: number
  b: number
}

export interface OneSchemaStyles {
  OneSchemaBlue: Hex
  // PRIMARY COLORS
  ColorPrimary100: Hex
  ColorPrimary80: Hex
  ColorPrimary20: Hex
  ColorPrimary10: Hex
  ColorPrimary15: Hex
  ColorPrimaryTransparent15: Hex
  ColorPrimaryTransparent10: Hex
  ColorPrimaryTransparent8: Hex
  ColorPrimaryTransparent5: Hex
  // SECONDARY COLORS
  ColorSecondary100: Hex
  ColorSecondary20: Hex
  // ERROR COLORS
  ColorErrorRed120: Hex
  ColorErrorRed100: Hex
  ColorErrorRed70: Hex
  ColorErrorRed15: Hex
  ColorErrorRed10: Hex
  ColorErrorRedTransparent30: Hex
  ColorErrorRedTransparent25: Hex
  ColorErrorRedTransparent20: Hex
  // SUCCESS COLORS
  ColorSuccessGreen120: Hex
  ColorSuccessGreen100: Hex
  ColorSuccessGreen70: Hex
  ColorSuccessGreen40: Hex
  ColorSuccessGreen20: Hex
  // WARNING COLORS
  ColorWarningYellow120: Hex
  ColorWarningYellow100: Hex
  ColorWarningYellow40: Hex
  ColorWarningYellow20: Hex
  ColorWarningYellowTransparent30: Hex
  ColorWarningYellowTransparent25: Hex
  ColorWarningYellowTransparent20: Hex
  // GRAY SCALE
  ColorBlack: Hex
  ColorGray80: Hex
  ColorGray50: Hex
  ColorGray20: Hex
  ColorGray10: Hex
  ColorGray5: Hex
  ColorGray2: Hex
  ColorWhite: Hex
  // LIGHTEN / DARKEN
  ColorBlackTransparent10: Hex
  ColorBlackTransparent20: Hex
  ColorBlackTransparent50: Hex
  ColorWhiteTransparent30: Hex
  // SELECTOR COLORS (eg. WorkspaceColorSelect)
  ColorSelectorRed100: Hex
  ColorSelectorRedTransparent10: Hex
  ColorSelectorRed10: Hex
  ColorSelectorYellow100: Hex
  ColorSelectorYellow10: Hex
  ColorSelectorYellowTransparent10: Hex
  ColorSelectorBlue100: Hex
  ColorSelectorBlue10: Hex
  ColorSelectorBlueTransparent10: Hex
  ColorSelectorGreen100: Hex
  ColorSelectorGreen10: Hex
  ColorSelectorBluePurple100: Hex
  ColorSelectorBluePurple10: Hex
  ColorSelectorPurple100: Hex
  ColorSelectorPurple10: Hex
  ColorSelectorPink100: Hex
  ColorSelectorPink10: Hex
  ColorSelectorPurplePink100: Hex
  ColorSelectorPurplePink10: Hex
  ColorSelectorGreenBlue100: Hex
  ColorSelectorGreenBlue10: Hex
  ColorSelectorOrange100: Hex
  ColorSelectorOrange120: Hex
  ColorSelectorOrange10: Hex
  ColorSelectorGray100: Hex
  ColorSelectorGray10: Hex
  // Excel green used to color excel icon (exports)
  ColorExcelGreen: Hex
  // HEADER, FOOTER, BACKGROUND
  ColorHeader: Hex
  ColorFooter: Hex
  ColorBorder: Hex
  ColorBackgroundPrimary: Hex
  ColorBackgroundPrimaryDark02: Hex
  ColorBackgroundSecondary: Hex
  ColorBackgroundSecondaryDark02: Hex
  ColorBackgroundSecondaryTransparent0: Hex

  // MODAL
  ColorModalMask: Hex
  ModalBorderRadius: string

  // BUTTONS
  ButtonBorderRadius: string
  ButtonBorderColor: Hex

  ButtonPrimaryFillColor: Hex
  ButtonPrimaryFillColorHover: Hex
  ButtonPrimaryTextColor: Hex
  ButtonPrimaryStrokeColor: Hex

  ButtonSecondaryFillColor: Hex
  ButtonSecondaryFillColorHover: Hex
  ButtonSecondaryTextColor: Hex
  ButtonSecondaryStrokeColor: Hex

  ButtonTertiaryFillColor: Hex
  ButtonTertiaryFillColorHover: Hex
  ButtonTertiaryTextColor: Hex
  ButtonTertiaryStrokeColor: Hex

  ButtonAlertFillColor: Hex
  ButtonAlertFillColorHover: Hex
  ButtonAlertTextColor: Hex
  ButtonAlertStrokeColor: Hex

  // FONTS
  FontUrl: string
  FontFamily: string
  FontMonoUrl: string
  FontFamilyMono: string
  FontColorPrimary: Hex
  FontColorSecondary: Hex
  FontColorPlaceholder: Hex
  FontColorLink: Hex
}

export const workspaceColors: string[] = [
  defaultStyles.ColorPrimary100,
  defaultStyles.ColorSelectorBlue100,
  defaultStyles.ColorSelectorBluePurple100,
  defaultStyles.ColorSelectorGreenBlue100,
  defaultStyles.ColorSelectorGreen100,
  defaultStyles.ColorSelectorOrange100,
  defaultStyles.ColorSelectorRed100,
  defaultStyles.ColorSelectorPink100,
  defaultStyles.ColorSelectorPurple100,
  defaultStyles.ColorSelectorPurplePink100,
]
