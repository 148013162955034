import React, { ReactElement } from "react"
import { Navigate, Outlet } from "react-router-dom"

import AccessDeniedPage from "~/shared/components/global/AccessDeniedPage"
import NotFound404Page from "~/shared/components/NotFound404Page"
import { getAdminSubdomainUrl } from "~/shared/util/appUrls"
import viewManager, { showInternalTools } from "~/shared/util/viewManager"

const isUserAuthenticated = () => {
  return Boolean(viewManager.get("user"))
}

export default function ProtectedRoute(): ReactElement | null {
  if (isUserAuthenticated()) {
    return <Outlet />
  }
  return <Navigate to="/login" replace />
}

function isOnAdminOrg() {
  // TODO: Make `admin` subdomain a requirement for local instances, then drop
  // this exception.
  if (process.env.NODE_ENV === "development") {
    return true
  }
  return viewManager.get("org")?.subdomain === "admin"
}

/**
 * Wrapper for routes that should only be accessible by internal admins when on
 * the `admin` org (or any org on dev environment).
 */
export function InternalAdminOrgOnlyRoute(): ReactElement | null {
  if (showInternalTools()) {
    if (!isOnAdminOrg()) {
      // Redirect to the admin subdomain, maintaining the path.
      return <Navigate to={getAdminSubdomainUrl(window.location.pathname)} />
    }
    return <Outlet />
  }
  return <NotFound404Page />
}

/**
 * Wrapper for routes that should only be accessible by internal admins.
 */
export function InternalAdminOnlyRoute(): ReactElement | null {
  if (showInternalTools()) {
    return <Outlet />
  }
  return <NotFound404Page />
}

interface ProtectedAccessRouteProps {
  canAccess: boolean
}

export function ProtectedAccessRoute({
  canAccess,
}: ProtectedAccessRouteProps): ReactElement | null {
  if (canAccess) {
    return <Outlet />
  }
  return <AccessDeniedPage />
}
