import { EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { List } from "~/shared/api/lists"
import { DenormalizedWorkspace, Workspace } from "~/shared/api/workspaces"
import {
  createLists,
  createWorkspace,
  deleteListById,
  deleteWorkspaceById,
  updateListWorkspaceId,
  updateWorkspace,
} from "~/shared/redux/actions"
import { ListAndWorkspaceId } from "~/shared/redux/lists"
import {
  NormalizedEntities,
  normalizeWorkspace,
  partialInitSchema,
} from "~/shared/redux/schema"

export const workspacesAdapter = createEntityAdapter<Workspace>()

type WorkspacesState = EntityState<Workspace>

/*****************
 * CORE REDUCERS *
 *****************/

const handleCreatedWorkspace = (
  state: WorkspacesState,
  action: { payload: DenormalizedWorkspace },
) => {
  const id = action.payload.id
  const normalized = normalizeWorkspace(action.payload)
  workspacesAdapter.addOne(state, normalized.entities.workspaces[id])
}

const handleUpdatedWorkspace = (
  state: WorkspacesState,
  action: { payload: DenormalizedWorkspace },
) => {
  const { id } = action.payload
  const normalized = normalizeWorkspace(action.payload)
  workspacesAdapter.updateOne(state, {
    id,
    changes: normalized.entities.workspaces[id],
  })
}

const handleDeletedWorkspaceById = (
  state: WorkspacesState,
  action: { payload: number },
) => {
  workspacesAdapter.removeOne(state, action.payload)
}

/******************
 * EXTRA REDUCERS *
 ******************/

const handleCreatedLists = (state: WorkspacesState, action: { payload: List[] }) => {
  const lists = action.payload
  lists.forEach((list) => {
    const workspace = state.entities[list.workspaceId]
    if (workspace) {
      workspace.listIds = [...(workspace.listIds ?? []), list.id]
    }
  })
}

const handleDeletedListById = (
  state: WorkspacesState,
  action: { payload: ListAndWorkspaceId },
) => {
  const { listId, workspaceId } = action.payload
  const workspace = state.entities[workspaceId]
  if (workspace) {
    workspace.listIds = workspace.listIds.filter((id) => id !== listId)
  }
}

const handleUpdatedListWorkspaceId = (
  state: WorkspacesState,
  action: {
    payload: {
      workspaceId: number
      listId: number
      newWorkspaceId: number
    }
  },
) => {
  const { workspaceId, listId, newWorkspaceId } = action.payload

  const oldWorkspace = state.entities[workspaceId]
  const newWorkspace = state.entities[newWorkspaceId]
  if (oldWorkspace && newWorkspace) {
    oldWorkspace.listIds = oldWorkspace.listIds.filter((id) => id !== listId)
    newWorkspace.listIds = [...newWorkspace.listIds, listId]
  }
}

export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState: workspacesAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Initialize store from viewData
      .addCase(
        partialInitSchema,
        (state, action: { payload: Partial<NormalizedEntities> }) => {
          if (action.payload.workspaces) {
            workspacesAdapter.removeAll(state)
            workspacesAdapter.addMany(state, action.payload.workspaces)
          }
        },
      )
      // Core Reducers
      .addCase(createWorkspace, handleCreatedWorkspace)
      .addCase(updateWorkspace, handleUpdatedWorkspace)
      .addCase(deleteWorkspaceById, handleDeletedWorkspaceById)
      // Extra Reducers
      .addCase(createLists, handleCreatedLists)
      .addCase(deleteListById, handleDeletedListById)
      .addCase(updateListWorkspaceId, handleUpdatedListWorkspaceId)
  },
})

export default workspacesSlice.reducer
