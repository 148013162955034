import MarkdownIt from "markdown-it"

const md = new MarkdownIt()
// get links to open in new tab.
// adapted from here: https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
const defaultRenderer = function (
  tokens: any,
  idx: any,
  options: any,
  env: any,
  self: any,
) {
  return self.renderToken(tokens, idx, options)
}

md.renderer.rules.link_open = function (tokens: any, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex("target")

  if (aIndex < 0) {
    tokens[idx].attrPush(["target", "_blank"]) // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = "_blank" // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRenderer(tokens, idx, options, env, self)
}

export function renderMarkdownString(str: string) {
  return md.render(str)
}
