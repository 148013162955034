import dayjs, { Dayjs } from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import timezone from "dayjs/plugin/timezone" // dependent on utc plugin
import utc from "dayjs/plugin/utc"

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

// Function for converting string Dates (passed from Ruby backend) into dayjs objects
// Need to parse this way to support Firefox/Safari
export function stringToDayjs(date: string): Dayjs {
  // In Ruby, calling .to_json on a Time returns the time in ISO8601 format.
  // Note that this is _different_ than what you get if you call .to_s on a
  // Time! Technically if you just pass a single string to dayjs then it
  // will try to parse it as an IS08601 date, but we'll provide the
  // format explicitly.
  //
  // https://day.js.org/docs/en/parse/string
  // https://day.js.org/docs/en/parse/string-format
  return dayjs(date, "YYYY-MM-DDTHH:mm:ss.SSSZ")
}

/**
 * Function for converting Dayjs Objects into strings given a format.
 */
export function dayjsToString(date: null | undefined, format?: string): null
export function dayjsToString(date: Dayjs, format?: string): string
export function dayjsToString(
  date: Dayjs | null | undefined,
  format?: string,
): string | null {
  return date ? date.utc().local().tz(dayjs.tz.guess()).format(format) : null
}

function formatServerString(serverString: string, format: string): string {
  return dayjsToString(stringToDayjs(serverString), format)
}

/**
 * Function to format a date passed from the server into the desired format.
 */
export function formatServerDateTime(
  serverString: string,
  format: "long" | "medium" | "short" = "short",
  options: {
    dateOnly?: boolean
    timeFirst?: boolean
    comma?: boolean
  } = {},
): string {
  const { dateOnly = false, timeFirst = false, comma = false } = options

  let dateFormat
  switch (format) {
    case "long":
      dateFormat = "MMMM D, YYYY"
      break
    case "medium":
      dateFormat = "MMM D, YYYY"
      break
    case "short":
      dateFormat = "MM/DD/YYYY"
      break
    default:
      // TypeScript should catch this, but just in case.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Invalid date format: ${format}`)
  }

  const timeFormat = "h:mm A"

  const dayjsFormat = dateOnly
    ? dateFormat
    : timeFirst
      ? `${timeFormat} on ${dateFormat}`
      : comma
        ? `${dateFormat}, ${timeFormat}`
        : `${dateFormat} ${timeFormat}`

  return formatServerString(serverString, dayjsFormat)
}
