import { EntityState, createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { Environment } from "~/shared/api/environment"
import {
  createEnvironment,
  deleteEnvironmentById,
  updateEnvironment,
} from "~/shared/redux/actions"
import {
  NormalizedEntities,
  normalizeEnvironment,
  partialInitSchema,
} from "~/shared/redux/schema"

export const environmentsAdapter = createEntityAdapter<Environment>()

type EnvironmentsState = EntityState<Environment>

/*****************
 * CORE REDUCERS *
 *****************/

const handleCreatedEnvironment = (
  state: EnvironmentsState,
  action: { payload: Environment },
) => {
  const id = action.payload.id
  const normalized = normalizeEnvironment(action.payload)
  environmentsAdapter.addOne(state, normalized.entities.environments[id])
}

const handleUpdatedEnvironment = (
  state: EnvironmentsState,
  action: { payload: Environment },
) => {
  const { id } = action.payload
  const normalized = normalizeEnvironment(action.payload)
  environmentsAdapter.updateOne(state, {
    id,
    changes: normalized.entities.environments[id],
  })
}

const handleDeletedEnvironmentById = (
  state: EnvironmentsState,
  action: { payload: number },
) => {
  environmentsAdapter.removeOne(state, action.payload)
}

export const environmentsSlice = createSlice({
  name: "environments",
  initialState: environmentsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Initialize store from viewData
      .addCase(
        partialInitSchema,
        (state, action: { payload: Partial<NormalizedEntities> }) => {
          if (action.payload.environments) {
            environmentsAdapter.removeAll(state)
            environmentsAdapter.addMany(state, action.payload.environments)
          }
        },
      )
      // Core Reducers
      .addCase(createEnvironment, handleCreatedEnvironment)
      .addCase(updateEnvironment, handleUpdatedEnvironment)
      .addCase(deleteEnvironmentById, handleDeletedEnvironmentById)
  },
})

export default environmentsSlice.reducer
