import { CustomizationOptions } from "~/shared/api/customization"
import { ImportType } from "~/shared/api/imports"
import { TemplateOverrides } from "~/shared/api/templates"
import { ImportConfig } from "~/shared/containers/ConfigProvider"

export function isEmbed() {
  // Currently determining whether it's an embed
  // based on URL. This could cause issue if we change URL!!
  return (
    window.location.pathname.startsWith("/embed-launcher") ||
    window.location.pathname.startsWith("/file-feeds-embed/launcher")
  )
}

export interface EmbedSessionConfig {
  userJwt: string
  importConfig: ImportConfig
  templateKey: string
  templateOverrides?: TemplateOverrides
  customizationKey?: string
  customizationOverrides?: CustomizationOptions

  // we would like this to always be true
  // but cannot guarantee it and, so,
  // include this option for backwards compat
  // with 'old' (pre-sdk) customers
  manualClose?: boolean
}

/**
 * Deprecated options config... options are now passed in as CustomizationOptions
 */
export interface EmbeddingOptions {
  autofixAfterMapping: boolean
  blockImportIfErrors: boolean
  acceptCodeHookSuggestions: boolean
  skipExportData: boolean
  contentOptions?: {
    upload?: {
      uploader?: {
        header?: string
        subheader?: string
      }
      infoSidebar?: {
        hideInfoBanner?: boolean
        infoBannerText?: string
        displayTemplateColumns: "required" | "all"
      }
    }
  }
}

// The input session config can have a lot of legacy options
// from different points in the SDK evolution
export interface InputEmbedSessionConfig extends EmbedSessionConfig {
  // for init-session
  sessionToken?: string
  resumeToken?: string

  // only care about at init
  eventWebhookKeys?: string[]

  // deprecated
  webhookKey?: string
  options?: EmbeddingOptions
}

// This is used to convert the old/legacy contentOptions which were passed in via SDK
// to the settings for the customization options which are currently in use..
// eventually should mark as deprecated and warn users that use contentOptions
export function embedOptionsToCustomizationOptions(options: EmbeddingOptions) {
  const customization: CustomizationOptions = {}

  if (options?.contentOptions) {
    customization.uploaderHeaderText = options.contentOptions.upload?.uploader?.header
    customization.uploaderSubheaderText =
      options.contentOptions.upload?.uploader?.subheader
    customization.uploaderShowSidebar = !!options.contentOptions.upload?.infoSidebar
    customization.uploaderSidebarDetails =
      options.contentOptions.upload?.infoSidebar?.displayTemplateColumns
    if (options.contentOptions.upload?.infoSidebar?.hideInfoBanner !== undefined) {
      customization.uploaderShowSidebarBanner =
        !options.contentOptions.upload?.infoSidebar?.hideInfoBanner
    }
    customization.uploaderSidebarBannerText =
      options.contentOptions.upload?.infoSidebar?.infoBannerText
  }

  // some legacy thing- you could use block_import_if_errors by url param
  // so merging that in with the embedding options here
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const urlBlock = urlParams.get("block_import_if_errors")
  const blockImportIfErrors =
    (urlBlock && urlBlock.trim().toLowerCase() === "true") || options?.blockImportIfErrors

  if (blockImportIfErrors !== undefined) {
    customization.importErrorUX = blockImportIfErrors ? "blockIfErrors" : "ignoreErrors"
  }

  if (options?.autofixAfterMapping !== undefined) {
    customization.autofixAfterMapping = options?.autofixAfterMapping
  }

  if (options?.acceptCodeHookSuggestions !== undefined) {
    customization.acceptCodeHookSuggestions = options?.acceptCodeHookSuggestions
  }

  return customization
}

// init `importConfig` based on legacy setups
// Note: the POST /v1/embeds endpoint mimics this logic
// to resolve importConfig and webhookKey when both fields
// are specified by the user. They should stay consistent.
export function migrateInputEmbedSessionConfig(input: InputEmbedSessionConfig) {
  const sessionConfig = { ...input } as EmbedSessionConfig

  if (input.webhookKey) {
    sessionConfig.importConfig = {
      type: ImportType.WEBHOOK,
      key: input.webhookKey,
    }
  } else if (!input.importConfig) {
    sessionConfig.importConfig = {
      type: ImportType.LOCAL,
      metadataOnly: input.options?.skipExportData,
    }
  }

  if (input.options && !sessionConfig.customizationOverrides) {
    sessionConfig.customizationOverrides = embedOptionsToCustomizationOptions(
      input.options,
    )
  }

  // TODO: Check that the session config is valid.
  return sessionConfig
}
