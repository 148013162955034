import type { OfflineTransportOptions } from "@sentry/core"
import * as Sentry from "@sentry/react"
import type { BaseTransportOptions, ClientOptions } from "@sentry/types"
import "antd/dist/antd.variable.min.css"

export function initSentry(environment: string, release: string | undefined): void {
  Sentry.init({
    environment,
    release,
    ...CLIENT_OPTIONS,
  })
}

const CLIENT_OPTIONS: SentryBrowserClientOptions = {
  dsn: "https://eca3dea8ec6f4320a3b48648c9aabdf8@o641017.ingest.sentry.io/5757481",

  // We don't use Sentry's performance monitoring right now,
  // so we also don't have to sample any data.
  // Tracing and error reporting is _not_ the same.
  tracesSampleRate: 0.0,

  // Enable offline storage.
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  transportOptions: {
    dbName: "oneschema-sentry-offline",
    maxQueueSize: 100,
  },

  ignoreErrors: [
    "Cannot redefine property: googletag",
    "grecaptcha is not defined",
    "ResizeObserver loop completed with undelivered notifications.",
    "ethereum",
    "Not implemented on this platform", // from LastPass extension
  ],

  // Attach stack traces to all captures, not just exceptions.
  attachStacktrace: true,

  // Ignore events originated from FullStory or Chrome extensions.
  beforeSend(event: Sentry.ErrorEvent): Sentry.ErrorEvent | null {
    try {
      const ex: Sentry.Exception | undefined = event.exception?.values?.[0]
      const frame: Sentry.StackFrame | undefined = ex?.stacktrace?.frames?.[0]
      const filename: string | undefined = frame?.filename
      if (
        filename &&
        ([
          // FullStory
          "s/fs.js",
          "/s/fs.js",
          // Browser extensions?
          "<anonymous>",
        ].includes(filename) ||
          filename.startsWith("chrome-extension://"))
      ) {
        return null
      }
    } catch (_err) {
      // Ignore any errors, let the event be logged.
    }
    return event
  },
}

/**
 * Temporary solution for typing issues with @sentry/* @ 8.*.
 */

type SentryBrowserClientOptions = Partial<
  ClientOptions<BrowserOfflineTransportOptions & BrowserTransportOptions>
>

// Copy from @sentry/browser/build/npm/types/transports/offline.d.ts
interface BrowserOfflineTransportOptions
  extends Omit<OfflineTransportOptions, "createStore"> {
  /**
   * Name of indexedDb database to store envelopes in
   * Default: 'sentry-offline'
   */
  dbName?: string
  /**
   * Name of indexedDb object store to store envelopes in
   * Default: 'queue'
   */
  storeName?: string
  /**
   * Maximum number of envelopes to store
   * Default: 30
   */
  maxQueueSize?: number
}

// Copy from @sentry/browser/build/npm/types/transports/types.d.ts
interface BrowserTransportOptions extends BaseTransportOptions {
  /** Fetch API init parameters. Used by the FetchTransport */
  fetchOptions?: RequestInit
  /** Custom headers for the transport. Used by the XHRTransport and FetchTransport */
  headers?: {
    [key: string]: string
  }
}
