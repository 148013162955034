import React, { ReactElement } from "react"

import ButtonLink from "~/shared/components/design-system/Button/ButtonLink"
import TextBodyText from "~/shared/components/design-system/Text/TextBodyText"
import TextH4 from "~/shared/components/design-system/Text/TextH4"

import "./AccessDeniedPage.less"

/**
 * AccessDeniedPage component if a user tries to access a page they do not have access to (based on user management)
 */
export default function AccessDeniedPage(): ReactElement | null {
  return (
    <div className="AccessDeniedPage">
      <div className="AccessDeniedPage__card">
        <TextH4 className="AccessDeniedPage__card-title">Access denied</TextH4>
        <TextBodyText className="AccessDeniedPage__card-subtitle">
          You do not have permission to access this page with your account. Please contact
          your admin for further assistance.
        </TextBodyText>
        <ButtonLink type="primary" className="thick" href="/">
          Return home
        </ButtonLink>
      </div>
    </div>
  )
}
