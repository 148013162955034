import React, { ReactElement, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import {
  CustomizationKeyToI18nKey,
  CustomizationOptions,
  DEFAULT_OPTIONS,
  SELF_SERVE_DEFAULT_OPTIONS,
} from "~/shared/api/customization"
import { ImportType } from "~/shared/api/imports"
import { ThemeContext } from "~/shared/containers/ThemeProvider"
import { Feature, isFeatureEnabled } from "~/shared/util/gating"
import viewManager from "~/shared/util/viewManager"

export interface WebhookImportConfig {
  type: ImportType.WEBHOOK
  key: string
}

export interface LocalImportConfig {
  type: ImportType.LOCAL
  metadataOnly?: boolean
}

interface BaseFileUploadImportConfig {
  type: ImportType.FILE_UPLOAD
  url: string
  headers?: { [header: string]: string }
}

interface CsvFileUploadImportConfig extends BaseFileUploadImportConfig {
  format: "csv"
  formatOptions?: {
    headerStyle?: "names" | "keys"
  }
}

interface JsonFileUploadImportConfig extends BaseFileUploadImportConfig {
  format: "json"
}

export type FileUploadImportConfig =
  | CsvFileUploadImportConfig
  | JsonFileUploadImportConfig

export type ImportConfig =
  | WebhookImportConfig
  | LocalImportConfig
  | FileUploadImportConfig

export interface ConfigContextState {
  options: CustomizationOptions
  importConfig?: ImportConfig
  devMode: boolean
}

export interface ConfigProviderProps {
  children: React.ReactNode
  applyBranding?: boolean
  customizationOverrides?: CustomizationOptions
  importConfig?: ImportConfig
  devMode?: boolean
}

export const ConfigContext = React.createContext({} as ConfigContextState)

// Provider that manages configs (customization options, import config, devMode). Allows users to specify options (eg.
// hiding OneSchema logo). Style customizations will be passed into the ThemeProvider,
// where they will be parsed and exposed.
export default function ConfigProvider(props: ConfigProviderProps): ReactElement | null {
  const initialCustomization = viewManager.get("customization")
  const org = viewManager.get("org")
  const initialOptions = initialCustomization?.options
  const defaultOptions = org?.isSelfServe ? SELF_SERVE_DEFAULT_OPTIONS : DEFAULT_OPTIONS
  const mergedOptions: CustomizationOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...initialOptions,
      ...props.customizationOverrides,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialOptions, props.customizationOverrides],
  )

  const { applyStyleOverrides } = useContext(ThemeContext)
  const { i18n } = useTranslation()
  const isBrandingEnabled = isFeatureEnabled(Feature.AdvancedBranding)
  useEffect(
    () => {
      if (props.applyBranding) {
        if (isBrandingEnabled) {
          applyStyleOverrides(mergedOptions)
        }

        // apply text overrides
        const overrides = Object.keys(CustomizationKeyToI18nKey).reduce(
          (textOverrides, customizationKey) => {
            const overrideText =
              mergedOptions[customizationKey as keyof CustomizationOptions]
            if (overrideText) {
              textOverrides[CustomizationKeyToI18nKey[customizationKey]] = overrideText
            }

            return textOverrides
          },
          {} as { [key: string]: any },
        )
        // "translation" is the default ns for i18next
        i18n.reloadResources(i18n.resolvedLanguage, "translation", () => {
          if (Object.keys(overrides).length) {
            i18n.addResources(i18n.resolvedLanguage ?? "en", "translation", overrides)
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mergedOptions, props.applyBranding, isBrandingEnabled],
  )

  return (
    <ConfigContext.Provider
      value={{
        options: mergedOptions,
        devMode: Boolean(props.devMode),
        importConfig: props.importConfig,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  )
}
