import fastSafeStringify from "fast-safe-stringify"
import stableStringify from "json-stable-stringify"
import { configure as safeStableConfigure } from "safe-stable-stringify"

import { JsonObject, JsonValue } from "~/shared/util/types"

export function parseJson(value: string): JsonValue | undefined {
  try {
    return JSON.parse(value) as JsonValue
  } catch (_err) {
    return undefined
  }
}

export function parseJsonObject(value: string): JsonObject | undefined {
  const parsed = parseJson(value)
  if (typeof parsed === "object" && parsed !== null) {
    return parsed as JsonObject
  }
  return undefined
}

/**
 * Safe, fast, but unstable, JSON.stringify.
 */
export function fastJsonStringify(value: JsonValue): string {
  return fastSafeStringify(value)
}

const _safeStableStringify = safeStableConfigure({
  bigint: false,
  circularValue: "[Circular]",
  deterministic: true,
  maximumBreadth: 10000,
  maximumDepth: 20,
  strict: true,
})

/**
 * Safe, fast, and stable, JSON.stringify.
 */
export function stableJsonStringify(value: JsonValue): string | undefined {
  return _safeStableStringify(value, /* replacer */ undefined, /* space */ 2)
}

/**
 * Safe, fast, and stable, JSON.stringify.
 */
export function prettyJsonStringify(value: JsonValue): string {
  return stableStringify(value, {
    space: 2,
    cycles: true, // Detect cycles and replace with a marker string.
  })
}
